import * as React from "react";
import Layout from "../components/Layout";
import SEO from "../components/SEO";

const software = [
  {
    category: "Locate files",
    name: "Everything",
    install: "winget install voidtools.Everything",
  },
  {
    category: "Java IDE",
    install: "winget install JetBrains.IntelliJIDEA.Ultimate",
    name: "IntelliJ IDEA",
  },
  {
    category: "C# IDE",
    name: "Visual Studio Community",
    install: "winget install Microsoft.VisualStudio.2022.Community",
  },
  {
    category: "Code Editor",
    name: "Microsoft Visual Studio Code",
    install: "winget install Microsoft.VisualStudioCode",
  },
  {
    category: "Peer to Peer Network",
    name: "ZeroTier One",
    install: "winget install ZeroTier.ZeroTierOne",
  },
  {
    category: "Peer to Peer Network",
    name: "Tailscale",
    install: "winget install tailscale.tailscale",
  },
  {
    category: "File Synchronization",
    name: "SyncTrayzor",
    install: "winget install SyncTrayzor.SyncTrayzor",
    remark: "Windows tray utility for Syncthing",
  },
  {
    category: "Web Browser",
    name: "Microsoft Edge",
    install: "winget install Microsoft.Edge",
  },
  {
    category: "Web Browser",
    name: "Cent Browser",
    install: "winget install CentStudio.CentBrowser",
  },
  {
    category: "Tunnel",
    name: "Clash for Windows",
    install: "winget install Fndroid.ClashForWindows",
    remark: "A Windows/macOS GUI based on Clash",
  },
  {
    category: "Application virtualization",
    name: "Sandboxie-Plus",
    install: "winget install Sandboxie.Plus",
  },
  {
    category: "Binary Code Analysis Tool",
    name: "IDA Freeware and Hex-Rays Decompilers",
    install: "winget install HexRays.IDA.Free",
  },
  {
    category: "Binary Debugger",
    name: "x64dbg",
    install: "https://github.com/x64dbg/x64dbg/releases",
  },
  {
    category: "Binary Debugger",
    name: "Cheat Engine",
    install: "https://cheatengine.org/downloads.php",
  },
  {
    category: "Security",
    name: "火绒安全软件",
    install: "https://www.huorong.cn/person5.html",
  },
  {
    category: "Data Recovery",
    name: "R-Studio",
    install: "https://www.r-studio.com/zhcn/Data_Recovery_Download.shtml",
  },
  {
    category: "Disk Utilities",
    name: "DiskGenius",
    install: "winget install Eassos.DiskGenius",
  },
  {
    category: "Synchronize Files",
    name: "FreeFileSync",
    install: "https://freefilesync.org/download.php",
  },
  {
    category: "Hex Editor",
    name: "010 Editor",
    install: "winget install SweetScape.010Editor",
  },
  {
    category: "Disk Editor",
    name: "WinHex",
    install: "http://www.winhex.com/winhex/",
  },
  {
    category: "Quick Look",
    name: "QuickLook",
    install: "winget install QL-Win.QuickLook",
  },
  {
    category: "Utility Software",
    name: "Sysinternals Suites",
    install: "winget install 9P7KNL5RWT25",
  },
  {
    category: "Messenger",
    name: "Telegram Desktop",
    install: "winget install Telegram.TelegramDesktop",
  },
  {
    category: "Markdown Editor",
    name: "Typora",
    install: "winget install Typora.Typora",
  },
  {
    category: "VPN",
    name: "WireGuard",
    install: "winget install WireGuard.WireGuard",
  },
  {
    category: "Network Traffic Analyzer",
    name: "Wireshark",
    install: "winget install WiresharkFoundation.Wireshark",
  },
  {
    category: "Network Traffic Analyzer",
    name: "科莱网络分析系统",
    install: "http://www.colasoft.com.cn/download/capsa.php",
  },
  {
    category: "Disk Space Analyzer",
    name: "WizTree",
    install: "winget install AntibodySoftware.WizTree",
  },
];

const SoftwarePage = () => {
  return (
    <Layout>
      <SEO title="Software" />
      <header className="my-10 text-center">
        <h1>Projects</h1>
        <div>
          I've used a lot of software in my 15 years of surfing the web, and
          here are some choices that I think are better in their category. If
          you are looking for related software, this list may be helpful to you.
        </div>
      </header>

      <table className="table-auto border-separate border-collapse border border-gray-400 w-full mb-20">
        <thead>
          <tr>
            <th className="p-4">用途</th>
            <th className="p-4">软件</th>
            <th className="p-4">安装</th>
            <th className="p-4">备注</th>
          </tr>
        </thead>
        <tbody>
          {software.map((project) => {
            return (
              <tr key={project.name}>
                <td className="border border-gray-300 p-4">
                  {project.category}
                </td>
                <td className="border border-gray-300 p-4">{project.name}</td>
                <td className="border border-gray-300 p-4">
                  {project.install}
                </td>
                <td className="border border-gray-300 p-4">{project.remark}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </Layout>
  );
};

export default SoftwarePage;
